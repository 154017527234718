const myaudio = (p5Instance) => {
  let music;
  let fft;
  let canvas;


  p5Instance.preload = () => {
    music = p5Instance.loadSound("/assets/audio/music1.mp3");
  };

  p5Instance.playMusic = () => {
    if ( p5Instance.isPlaying() ) {
      document.getElementById('play').innerHTML = 'START';
      return p5Instance.music.stop();
    }
    document.getElementById('play').innerHTML = 'STOP';
    p5Instance.music.loop();
  };

  p5Instance.isPlaying = () => p5Instance.music?.isPlaying();

  p5Instance.isLoaded = () => p5Instance.music?.isLoaded();

  p5Instance.windowResize = () => {
    p5Instance.resizeCanvas(p5Instance.windowWidth, p5Instance.windowHeight);
  };

  p5Instance.setup = () => {
    p5Instance.createCanvas(p5Instance.windowWidth, p5Instance.windowHeight);
    p5Instance.music = music;
    p5Instance.fft = new p5.FFT();
    p5Instance.music.amp(1);

    Toastify({
      text: "Music loaded",
      className: "warning",
      style: {
        background: "linear-gradient(to right, #6366f1, #ec4899)",
      }
    }).showToast();
    document.getElementById('loadingmusic').remove();
  };

  p5Instance.draw = () => {
    const r = 400;
    const r2 = 80;
    const r3 = 185;
    const rad = 50;
    const waveform = p5Instance.fft.waveform();

    p5Instance.background(0);
    p5Instance.clear();
    p5Instance.noFill();
    p5Instance.strokeWeight(2);
    p5Instance.stroke(236, 72, 153);
    p5Instance.translate(p5Instance.width / 2, p5Instance.height / 2);
    p5Instance.ellipse(0, 0, rad, rad);
    p5Instance.stroke(236, 72, 153);

    for (let i = 0; i < waveform.length; i += 2) {
      const x = r * p5Instance.cos(i * 2 * p5Instance.PI / waveform.length);
      const y = r * p5Instance.sin(i * 2 * p5Instance.PI / waveform.length);
      const x2 = (r + waveform[i] * 400) * p5Instance.cos(i * 2 * p5Instance.PI / waveform.length);
      const y2 = (r + waveform[i] * 400) * p5Instance.sin(i * 2 * p5Instance.PI / waveform.length);
      p5Instance.line(x, y, x2, y2);
      p5Instance.point(x, y);
    }

    p5Instance.stroke(168, 85, 247);
    for (let i = 0; i < waveform.length; i += 64) {
      const x = r2 * p5Instance.cos(i * 2 * p5Instance.PI / waveform.length);
      const y = r2 * p5Instance.sin(i * 2 * p5Instance.PI / waveform.length);
      const x2 = (r2 + waveform[i] * 200) * p5Instance.cos(i * 2 * p5Instance.PI / waveform.length);
      const y2 = (r2 + waveform[i] * 200) * p5Instance.sin(i * 2 * p5Instance.PI / waveform.length);
      p5Instance.line(x, y, x2, y2);
      p5Instance.point(x, y);
    }

    p5Instance.stroke(99, 102, 241);
    for (let i = 0; i < waveform.length; i += 10) {
      const x = r3 * p5Instance.cos(i * 2 * p5Instance.PI / waveform.length);
      const y = r3 * p5Instance.sin(i * 2 * p5Instance.PI / waveform.length);
      const x2 = (r3 + waveform[i] * 100) * p5Instance.cos(i * 2 * p5Instance.PI / waveform.length);
      const y2 = (r3 + waveform[i] * 100) * p5Instance.sin(i * 2 * p5Instance.PI / waveform.length);
      p5Instance.line(x, y, x2, y2);
      p5Instance.point(x, y);
    }
    
    p5Instance.stroke(0, 0, 255);
    p5Instance.beginShape();
    for (let i = 0; i < waveform.length; i += 28) {
      const x2 = (r + waveform[i] * 140) * p5Instance.cos(i * 2 * p5Instance.PI / waveform.length);
      const y2 = (r + waveform[i] * 140) * p5Instance.sin(i * 2 * p5Instance.PI / waveform.length);
      p5Instance.push();
      p5Instance.stroke(255);
      p5Instance.strokeWeight(5);
      p5Instance.point(x2, y2);
      p5Instance.pop();
    }
    p5Instance.endShape();
  };
};

const audioP5 = new p5(myaudio, 'canvas');

document.getElementById('play').addEventListener('click', () => {
  if ( !audioP5.isLoaded() ) {
    return Toastify({
      text: "The music file hasn't loaded yet. Please wait",
      className: "warning",
      style: {
        background: "linear-gradient(to right, #ec4899, #ec4899)",
      }
    }).showToast();
  }
  audioP5.playMusic();
});
